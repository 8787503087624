<template>
  <div class="card-row">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CardRow"
}
</script>

<style lang="stylus">
.card-row {
  width 100%
  display flex
  flex-direction row
  flex-wrap wrap
  align-items stretch
  justify-content space-between
  position relative

  &:not(:last-child) {
    margin-bottom 24px
  }
}
</style>