export default function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·,:;";
  var to = "aaaaeeeeiiiioooouuuunc----";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace("#", 'sharp')
  str = str.replace(/[^a-z0-9 / . -]/g, '')
  str = str.replace(/\s+/g, '-')
  str = str.replaceAll("/", '--'); // collapse whitespace and replace by -


  return str;
}