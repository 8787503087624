<template>
  <header class="main-header">
    <router-link class="main-header_left" to="/">
      <img src="/assets/images/iconWhite.svg" alt />
    </router-link>
    <div class="main-header_mid_big-screen">
      <ul class="main-header_mid_big-screen_list">
        <li>
          <a target="_blank" href="https://haply.co/">home</a>
        </li>
        <li>
          <a target="_blank" href="https://join.haply.co/">careers</a>
        </li>
        <li>
          <a href="/" class="doc">developers</a>
        </li>
      </ul>
    </div>
    <div class="main-header_mid_small-screen">
      <ul class="main-header_mid_small-screen_list" v-if="burgerOpen">
        <li>
          <a target="_blank" href="https://haply.co/">home</a>
        </li>
        <li>
          <a target="_blank" href="https://join.haply.co/">careers</a>
        </li>
        <li>
          <a href="/" class="doc">developers</a>
        </li>
      </ul>
    </div>
    <div class="main-header_right_small-screen">
      <img ref="burger" @click="burgerClick" src="/assets/images/burger.svg" alt />
    </div>
    <div class="main-header_right_big-screen"></div>
  </header>
</template>

<script>
import SearchEngine from "./SearchEngine";

export default {
  name: "MainHeader",
  components: {
    SearchEngine
  },
  data() {
    return {
      burgerOpen: false
    };
  },
  methods: {
    burgerClick() {
      this.burgerOpen = !this.burgerOpen;
    }
  }
};
</script>

<style lang="stylus" scoped>
.main-header {
  background linear-gradient(90deg, $greyMatter, $blackOut)
  width 100vw
  max-width 100%
  height $headerHeight
  position fixed
  top 0
  left 0
  z-index 2
  display flex
  justify-content space-between
  align-items center
  padding 10px $horPadding
  color white

  a {
    // margin 0
  }

  &_left {
    display flex
    height 100%
    align-items center

    img {
      height 100%
      width auto
      margin-right 8px
    }
  }

  h1 {
    color white
    font-size 1.3rem
  }

  &_mid {
    &_small-screen, &_big-screen {
      ul {
        padding-left 0
        list-style-type none
        margin 0
      }
    }

    +below(770px) {
      &_big-screen {
        display none
      }

      &_small-screen {
        display block !important

        ul {
          position absolute
          top 50px
          left 0
          width 100%
          background black
          display block

          li {
            padding 10px $horPadding
            border-bottom 1px solid white
          }
        }
      }
    }

    &_big-screen {
      &_list {
        display flex

        li {
          margin 0 15px
          position relative
          cursor pointer

          &:before {
            transition all 0.2s
            content ''
            width 0
            height 1px
            position absolute
            bottom 0
            left 0
            background white
          }

          &:hover {
            &:before {
              width 100%
            }
          }
        }
      }
    }

    &_small-screen {
      display none
    }
  }

  &_right {
    +below(770px) {
      &_big-screen {
        display none
      }

      &_small-screen {
        display block !important
      }
    }

    &_small-screen {
      display none
    }
  }
}
</style>