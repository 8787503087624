<template>
  <div class="doc-article">
    <div class="doc-article_content">
      <div class="doc-article_content_breadcrumb">{{breadCrumbify(doc.path)}}</div>
      <div v-html="mdToHTML" class="doc-article_content_body" ref="articleBody"></div>
    </div>
  </div>
</template>

<script>
import { marked } from "marked";
import hljs from 'highlight.js/lib/core';
import csharp from 'highlight.js/lib/languages/csharp';
import slugify from "../../utils/js/slugify";

hljs.registerLanguage('csharp', csharp);

marked.setOptions({
  highlight: function(code) {
    return hljs.highlightAuto(code).value
  }
});

export default {
  name: "DocArticle",
  props: ["doc"],
  data() {
    return {
      breakPoint: 1300
    };
  },
  mounted() {
    this.watchAs();
  },

  methods: {
    breadCrumbify(string) {
      return string.replaceAll("/", " / ");
    },
    onOpenMenu() {
      this.$store.state.collapsedSideBar = false;
    },
    watchAs() {
      if (this.$refs.articleBody.querySelectorAll("a").length > 0) {
        this.$refs.articleBody.querySelectorAll("a").forEach(a => {
          a.addEventListener("click", e => {
            if (a.getAttribute("href").includes("docs:")) {
              this.$router.push(
                "/documentation/" +
                  slugify(a.getAttribute("href").split("docs:")[1])
              );
            } else {
              window.open(a.getAttribute("href"), "_blank");
            }
            e.preventDefault();
          });
        });
      }
    }
  },
  beforeDestroy() {},
  computed: {
    mdToHTML() {
      let str = this.doc.article;
      let newStr = "";
      str.split("\n").forEach(element => {
        if (element === "") newStr += "\n";
        else newStr += element;
      });

      const regex = /(\(docs:).*?\)/g;

      const matches = str.match(regex);
      if (matches) {
        matches.forEach(match => {
          let link = match.replace(/\(docs:|\)/g, "");
          str = str.replace(link, slugify(link));
        });
      }

      return marked.parse(str);
    },
    articleSelectedPart() {
      return this.$store.state.articleSelectedPart;
    }
  },
  watch: {
    articleSelectedPart(newValue, oldValue) {
      this.$refs.articleBody.querySelectorAll(`h2`).forEach(el => {
        if (el.innerHTML == newValue) {
          const y = el.getBoundingClientRect().top + window.scrollY - 70;
          window.scroll({
            top: y,
            behavior: "smooth"
          });
        }
      });
    },
    mdToHTML(newValue, oldValue) {
      this.$nextTick(() => {
        this.watchAs();
      });
    }
  }
};
</script>

<style lang="stylus">
@import url('//cdnjs.cloudflare.com/ajax/libs/highlight.js/11.5.1/styles/stackoverflow-dark.min.css')

.doc-article {
  width 100vw
  max-width 100%
  min-height 100vh
  display flex
  flex-direction column
  align-items center
  margin-top $headerHeight
  padding-bottom 70px

  +below(1000px) {
    display block
    padding 0 $horPadding
  }

  &_content {
    width 720px

    +below(900px) {
      width 100% !important
    }

    +below(1600px) {
      width 600px
    }

    &_breadcrumb, &_breadcrumb_collapse {
      color grey
      font-size 0.85rem
      padding 20px 0
      font-weight 100
      font-family myriad-pro-semiextended
    }

    &_body {
      color #222

      line-height, h1 {
        margin 30px 0
        font-size 2.4rem
      }

      br {
        margin 0 0
      }

      strong {
        font-weight 600
      }

      h1, h2, h3, h4 {
        border-bottom solid #ddd 1px
        padding-bottom 0.3em
        font-weight 400
      }

      h1, h2, h3, h4, h5, h6 {
        &:target::before {
          content "";
          display block;
          height 70px;
          margin -70px 0 0;
        }
      }

      h2, h3, h4 {
        margin-top 30px
        margin-bottom 20px
      }

      ul {
        padding-left 20px

        li {
          margin 10px 0
          list-style-type disc
        }

        margin-bottom 1rem
      }

      p {
        margin-bottom 1rem
        line-height 1.85em
      }

      a {
        text-decoration none
      }

      table {
        border-spacing 10px
        border-collapse collapse
        border-radius 5px
        background rgb(245, 245, 245)
        empty-cells hide
        width 100%

        td, th {
          padding 10px 20px

          &:empty {
            display: none;
          }
        }

        td {
          font-weight 100
          color #444

          strong {
            font-weight 400
            color black
          }
        }
        
        tbody {
          tr:not(:last-child) {
            border-bottom solid #ddd 1px
          }
        }
      }

      blockquote {
        background $stone !important
        padding 20px
        margin 10px 0
        font-size 0.9em
        border-radius 5px

        :last-child {
          margin-bottom unset
        }
      }

      mark {
        background-color yellow
      }

      img {
        width 100% !important
        height auto
        margin 10px 0
        border-radius 5px
      }

      pre {
        background #1c1b1b !important
        padding 20px
        border-radius 5px
        margin-bottom 10px
        margin-top 10px
        white-space pre-wrap

        code {
          color #eee
          font-family 'Courier', monospace, sans-serif
          font-size 0.8rem

          * {
            font-family 'Courier', monospace, sans-serif
            font-size 0.8rem
          }
        }
      }
    }
  }
}
</style>