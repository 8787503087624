<template>
  <header class="docs-header">
    <div class="docs-header_left">
      <span class="docs-header_left_links">
        <a href="https://haply.co/" class="h-100"><img src="/assets/images/iconWhite.svg" class="docs-header_logo" alt /></a>
        <span>
          <router-link to="/"><span class="docs-header_title"><strong>Inverse3</strong> Developer Hub</span></router-link>
          <!-- <span v-if="breadcrumbs && breadcrumbs.length > 0" class="docs-header_left_breadcrumbs">
            <span v-for="breadcrumb in breadcrumbs" class="docs-header_left_breadcrumb">
              <router-link to="/">{{breadcrumb}}</router-link>
            </span>
          </span> -->
        </span>
      </span>
    </div>
    <div class="docs-header_mid_big-screen">
      <ul class="docs-header_nav_links">
        <li><router-link to="/"><span>Home</span></router-link></li>
        <!-- <li><router-link to="/getting-started"><span>Getting started</span></router-link></li> -->
        <!-- <li><router-link to="/docs"><span>Apps</span></router-link></li> -->
        <!-- <li><router-link to="/docs"><span>SDK</span></router-link></li> -->
        <!-- <li><router-link to="/docs"><span>Documentation</span></router-link></li> -->
        <li><router-link to="/releases/latest"><span>Downloads</span></router-link></li>
      </ul>
    </div>
    <div class="docs-header_mid_small-screen">
      <ul class="main-header_mid_small-screen_list" v-if="burgerOpen">
        <li>
          <a target="_blank" href="https://haply.co/">home</a>
        </li>
        <li>
          <a target="_blank" href="https://join.haply.co/">careers</a>
        </li>
        <li>
          <a href="/" class="doc">developers</a>
        </li>
      </ul>
    </div>
    <div class="docs-header_right_big-screen">
      <ul class="docs-header_nav_more">
        <li>
          <a href="https://haply.co/" target="_blank" class="docs-header_nav_link-external">
            Main site&nbsp;
             <open-in-new class="icon-inline"/>
          </a>
        </li>
      </ul>
      <!-- <search-engine /> -->
    </div>
    <div class="docs-header_right_small-screen">
      <img ref="burger" @click="burgerClick" src="/assets/images/burger.svg" alt />
      <!-- <search-engine /> -->
    </div>
  </header>
</template>

<script>
import SearchEngine from "../UI/SearchEngine";
import OpenInNew from "../icons/OpenInNew";

export default {
  name: "DocsHeader",
  props: ["breadcrumbs"],
  components: {
    SearchEngine,
    OpenInNew,
  },
  data() {
    return {
      burgerOpen: false
    };
  },
  methods: {
    burgerClick() {
      this.burgerOpen = !this.burgerOpen;
    }
  }
};
</script>
<style lang="stylus" scoped>
.icon-inline {
  width 0.8em
  height 0.8em
  max-width 0.8em
  max-height 0.8em
}

.h-100 {
  height 100%
}

@css {
  .docs-header-light .docs-header_logo {
    filter: brightness(0%);
  }
}

.docs-header {
  width 100vw
  max-width 100%
  height $headerHeight
  position absolute
  top 0
  left 0
  z-index 2
  display flex
  justify-content space-between
  align-items center
  padding 10px $horPadding
  color white

  &_title {
    white-space nowrap
  }

  & > div {
    width calc(100% / 3)
  }

  &:not(-docs-header-light) .docs-header_title {
    font-weight 200
  }

  svg.icon /deep/ path:not([fill="none"]) {
    fill #fff 
  }

  &_nav {
    &_link-external {
      font-weight 200
    }
    &_links {
      & li {
        & a {
          & span {
            white-space nowrap
          }
        }
      }
    }
  }
  
  &-light {
    position fixed
    //ackground linear-gradient(90deg, #fff, #a8f)
    background white
    color #444

    svg.icon /deep/ path:not([fill="none"]) {
      fill #444  
    }

    .docs-header_title {
      color #777
    }

    .docs-header_nav {
      &_link-external {
        &:hover {
          color $active
          filter brightness(80%)
          svg.icon /deep/ path:not([fill="none"]) {
            fill $active   
            filter unset
          }
        }
      }
    }

    strong {
      color black
      font-weight 400
    }

    border-bottom 1px solid #ddd

    & .docs-header_nav {
      &_links {
        color #555
        & * {
          font-weight 200
        }
      }
    }

    & .docs-header_nav_more {
      & * {
        font-weight 200
      }
    }
  }

  a {
    // margin 0
    text-decoration none
  }

  &_left {
    display flex
    flex-direction row
    flex-wrap nowrap
    height 100%
    max-height 100%

    &_links {
      display flex
      flex-wrap nowrap
      flex-direction row
      height 100%
      max-height 100%
      align-items center
  
      & > :first-child {
        display flex
        align-items center
        margin-right 30px
      }

      img {
        height 38px
        max-height 38px
        width auto
      }
    }

    &_breadcrumbs {
      padding-left 2px
      & * {
        font-weight 200
        font-size 1em
        color #999
      }
    }

    &_breadcrumb {
      position relative
      &:before {
        content "/"
        padding-left 4px
        padding-right 4px
        color #ccc
      }

      & > a {
        font-size 1em

        &:hover {
          color $active
          fill $active
          filter brightness(80%)
        }
      }
    }
  }

  h1 {
    color white
    font-size 1.3rem
  }

  &_mid {
    &_small-screen, &_big-screen {
      display flex
      flex-direction row
      flex-wrap nowrap
      align-items center
      justify-content center
    }

    +below(770px) {
      &_big-screen {
        display none
      }

      &_small-screen {
        display flex !important

        ul {
          position absolute
          top 50px
          left 0
          width 100%
          background black
          display block
          list-style none
          padding-left 0
          
          li {
            padding 10px $horPadding
            border-bottom 1px solid white
          }
        }
      }
    }

    &_big-screen {
      height calc(100% + 21px)
      margin -10px 0
      ul {
        padding-left 0
        display flex
        flex-direction row
        flex-wrap nowrap
        align-items center
        justify-content center
        height 100%
        margin 0

        li {
          display block
          height 100%
          
          a {
            padding-left 30px
            padding-right 30px
            padding-top 2px
            border-bottom 2px solid #fff0
            height 100%
            display flex
            align-items center
            &:hover {
              color #fff
              border-bottom 2px solid #fffd
            }

            &.router-link-exact-active > span {
              position relative

              &::after {
                content: " ";
                position: absolute;
                right: -10px;
                top: calc(50% - 5px);
                width: 6px;
                height: 6px;
                border-radius: 3px;
                background: $active;
              }
            }
          }
        }
      }

      ul:not(:hover) li a.router-link-exact-active {
        color white
        border-bottom 2px solid #fffd
      }
    }

    &_small-screen {
      display none
    }
  }

  &_right {
    &_small-screen, &_big-screen {
      display flex
      flex-direction row
      flex-wrap nowrap
      align-items center
      justify-content flex-end

      li {
        &:last-child {
          padding-right 0
        }

        a.doc {
          position relative

          &:before {
            content 'alpha'
            position absolute
            top -40%
            right -20%
            text-transform uppercase
            font-size 0.55rem
          }
        }
      }
    }

    +below(770px) {
      &_big-screen {
        display none
      }

      &_small-screen {
        display flex !important

        ul {
          position absolute
          top 50px
          left 0
          width 100%
          background black
          display block

          li a {
            padding 10px $horPadding
            border-bottom 1px solid white
          }
        }
      }
    }

    &_big-screen {
      height calc(100% + 21px)
      margin -10px 0
      ul {
        padding-left 0
        display flex
        flex-direction row
        flex-wrap nowrap
        align-items center
        justify-content center
        height 100%
        margin 0

        li {
          display block
          height 100%
          padding-left 30px
          padding-right 30px
          
          a {
            height 100%
            display flex
            align-items center
          }
        }
      }
    }

    &_small-screen {
      display none
    }
  }
}

.docs-header-light .docs-header_mid_big-screen {
  ul {
    li {
      a {
        padding-top 3px
        border-bottom 3px solid alpha($active, 0)
      
        &:hover {
          color $active
          border-bottom 3px solid $active
          * {
            filter brightness(90%)
          }
        }
      }
    }
  }

  ul:not(:hover) {
    li {
      a.router-link-exact-active {
        color $active
        border-bottom 3px solid $active
        * {
          filter brightness(90%)
        }
      }
    }
  }
}

</style>