<template>
  <div id="app">
    <!-- <main-header /> -->
    <router-view />
    <main-footer />
  </div>
</template>
<script>
import MainHeader from "./components/UI/MainHeader";
import MainFooter from "./components/UI/MainFooter";

export default {
  components: {
    MainHeader,
    MainFooter
  },
  created() {
    this.$store.dispatch("fetchDocs");
  }
};
</script>

<style lang="stylus">
  
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap')

* {
  box-sizing border-box
  font-family 'myriad-pro', sans-serif
}

body {
  margin 0
}

h1, h2, h3, h4, h5, h6, p, span, a {
  margin 0
  font-weight 400
}

strong {
  font-weight 600
}

p {
  line-height 1.6rem
}

a {
  //text-decoration none
  color inherit
}

br {
  display block
  content ''
}

select {
  border none
  border-radius 5px
  border-right 4px transparent solid
}

.icon-inline {
  width 0.8em
  height 0.8em
  max-width 0.8em
  max-height 0.8em
}

.linear-wipe {
  text-align: center
  
  background: linear-gradient(110deg, #FFF 20%, $lavender 40%, $lavender 60%, #FFF 80%)
  background-size: 200% auto
  
  color: #000
  background-clip: text
  text-fill-color: transparent
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent
  
  animation: shine 2s linear infinite
  @keyframes shine {
    to {
      background-position: -200% center
    }
  }
}
</style>
