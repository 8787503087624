<template>
  <div class="tree-menu" :class="`depth${depth}`">
    <div v-if="isCurrentArticle()" class="currentArticle">
      <div class="indenters">
        <div v-for="i in depth" :key="i" class="indenters_indent"></div>
      </div>
      <h3 :style="nameStyle">{{ name }}</h3>
    </div>
    <div v-else-if="children.length == 0" class="clickable" @click="selectArticle">
      <div class="indenters">
        <div v-for="i in depth" :key="i" class="indenters_indent"></div>
      </div>

      <h3 :style="nameStyle">{{ name }}</h3>
    </div>

    <div v-else-if="depth != 0" class="has-child" @click="toggleChildren" :style="nameStyle">
      <div class="indenters">
        <div v-for="i in depth" :key="i" class="indenters_indent"></div>
      </div>
      <div class="chevroned">
        <img class="chevroned_chevron" ref="chevron" src="/assets/images/chevron.svg" />
        <h3>{{ name }}</h3>
      </div>
    </div>
    <div v-else class="section" @click="toggleChildren" :style="nameStyle">
      <div class="section_button">
        <div class="chevroned">
          <img class="chevroned_chevron" ref="chevron" src="/assets/images/chevron.svg" />
          <h3>{{ name }}</h3>
        </div>
      </div>
    </div>
    <div v-if="showChildren">
      <tree-menu
        v-for="(child, i) in children"
        :children="child.children"
        :name="child.name"
        :depth="depth+1"
        :key="i"
        :parentPath="parentPath+'/'+child.name"
      ></tree-menu>
    </div>
  </div>
</template>
<script>
import slugify from "../../utils/js/slugify";

export default {
  props: ["name", "children", "depth", "parentPath"],
  name: "tree-menu",
  data() {
    return {
      showChildren: false
    };
  },
  mounted() {
    this.childIsActive();
  },
  computed: {
    nameStyle() {
      return {
        // paddingLeft: `${this.depth * 10}px`
      };
    }
  },
  methods: {
    childIsActive() {
      console.log(this.name, this.depth);
      if (
        this.$route.params.articlePath.split("--")[this.depth + 1] ==
        slugify(this.name)
      ) {
        this.toggleChildren();
      } else if (this.depth == 0) {
        this.toggleChildren();
      }
    },
    selectArticle() {
      const foundDoc = this.$store.state.docsArticles.find(
        el => el.path == this.$store.state.currVersion + "/" + this.parentPath
      );
      this.$router.push("/documentation/" + slugify(foundDoc.path));
    },
    isCurrentArticle() {
      return (
        this.$route.params.articlePath ==
        this.$store.state.currVersion + "--" + slugify(this.parentPath)
      );
    },
    toggleChildren() {
      if (this.$refs.chevron) {
        this.$refs.chevron.classList.toggle("on");
        this.showChildren = !this.showChildren;
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.tree-menu {

  &.depth0 {
    border-bottom 1px solid #ddd
    margin-left -20px
    margin-right -20px
    padding-left 20px
    padding-right 20px

    & > :not(section) {
      margin-bottom 10px
    }
  }

  .currentArticle, .clickable, .has-child {
    position relative
    display flex
  }

  .indenters {
    display flex

    &_indent {
      height 100%
      width 1px
      background transparent
      margin 0 8px
    }
  }

  > :nth-child(2) > :first-child > :first-child > .indenters > :last-child {
    height calc(100% - 6px)
    margin-top 6px
  }

  > :nth-child(2) > :last-child > :first-child > .indenters > :last-child {
    height calc(100% - 6px)
    margin-bottom 6px
  }

  .chevroned {
    display flex
    align-items center
    justify-content flex-start
    width 100%

    &_chevron {
      transform scale(1.5, 1) rotate(-90deg)
      transition transform 0.1s
      width 10px
      height auto
      margin-right 0.5em

      &.on {
        transform scale(1, 1.5)
      }
    }
  }

  h3 {
    font-size 0.9rem
    font-weight inherit
    padding 4px 0
  }

  .section {
    cursor pointer
    margin 10px 0

    h3 {
      font-size 1.2rem
      font-weight 600
    }

    &_button {
      display flex
      justify-content space-between
      align-items center
    }

    &_line {
      margin 10px
      width 80%
      height 1px
      background #eee
    }
  }

  .currentArticle {
    background #eee
    // font-weight 600
    // margin-left 20px
  }

  .clickable {
    position relative
    overflow hidden
    transition all 0.3s
    beforeHover()
    cursor pointer
    // margin-left 20px

     h3 {
      font-size 0.9rem
      padding 4px 0
     }
  }

  .currentArticle {
    h3 {
      font-size 0.9rem
      padding 4px 0
    }
  }

  .clickable, .currentArticle {
    font-weight 100
    margin-left calc(10px + 0.5em)
  }

  .has-child {
    cursor pointer
  }
}
</style>