<template>
  <div class="home">
    <webgl-background />
    <div class="background-fade"></div>
    <docs-header class="docs-header-home"/>
    <div class="home_wrapper">
      <div class="home_wrapper_heading">
        <h1 class="hero_title" ref="title">Inverse3<br/><small>Developer Hub</small></h1>
        <span class="hero_subtitle" ref="underTitle">
          Build something incredible.
        </span>
      </div>

      <div ref="introText">
        <!-- <p>
          New here? Check out our <router-link to="/">getting started</router-link> page.
        </p> -->

        <!-- <p>
          Our documentation is structured according to the <a href="https://diataxis.fr/" target="_blank">diátaxis</a> framework:<br/>
        </p> -->
      </div>
      <card-layout>
        <!-- <card-row>
          <card class="card-dark" ref="startCard">
            <h3>🚀 Getting started</h3>

          </card>
          <card class="card-dark" ref="docsCard">
            <h3>📖 Documentation</h3>
          </card>
        </card-row> -->
        <card-row>
          <card class="card-dark card-wide" ref="downloadsCard" href="/releases/latest">
            <div class="card-link-external">
              <a href="https://haply.co/" target="_blank" class="docs-header_nav_link-external">
                <svg class="icon-inline" viewBox="0 0 24 24" >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M18 19H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h5c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.11 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6c0-.55-.45-1-1-1s-1 .45-1 1v5c0 .55-.45 1-1 1zM14 4c0 .55.45 1 1 1h2.59l-9.13 9.13c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L19 6.41V9c0 .55.45 1 1 1s1-.45 1-1V4c0-.55-.45-1-1-1h-5c-.55 0-1 .45-1 1z"  fill="#000000" />
                </svg>
              </a>
            </div>
            <h3>📦 Downloads</h3>
            Grab the latest versions of Inverse3 apps and SDK components like the HardwareAPI, Device Dashboard, and more.&nbsp;🔥
            <!-- To grab the latest HardwareAPI or Device Dashboard releases,<br/>head over to our 🔥&nbsp;<router-link to="/releases/latest">latest&nbsp;releases</router-link>&nbsp;🔥 page. -->
          </card>
        </card-row>
        <!-- <card-row>
          <card class="card-dark" ref="learnCard">
            <h3>👨‍🎓 Learn</h3>
            <div class="card_links">
              <strong>Introduction</strong>
              <ul>
                <li><router-link to="/learn/haptics-101" class="release-link">"Hello Wall"</router-link></li>
                <li><router-link to="/learn/haptics-101" class="release-link">"Hello Sphere"</router-link></li>
              </ul>
              <strong>Full projects</strong>
              <ul>
                <li>
                  <router-link to="/" class="docs-link">Xylophone in Unity</router-link>
                </li>
              </ul>
            </div>
          </card>
          <card class="card-dark" ref="guidesCard">
            <h3>⚡ Quick guides</h3>
            <div class="card_links">
              <strong>Inverse3</strong><br/>
              <ul>
                <li>
                  <router-link to="/" class="docs-link">Device setup 🏄🏽</router-link>
                </li>
              </ul>
              <strong>HardwareAPI</strong>
              <ul>
                <li><router-link to="/" class="docs-link">Device connection 🤝</router-link></li>
                <li><router-link to="/" class="docs-link">Force-feedback 👉👈</router-link></li>
              </ul>
            </div>
          </card>
        </card-row>
        <card-row>
          <card class="card-dark" ref="divesCard">
            <h3>🤯 Deep dives</h3>
            <div class="card_links">
              <ul>
                <li><router-link to="/" class="docs-link">The sense of touch</router-link></li>
                <li><router-link to="/" class="docs-link">Force-feedback loops</router-link></li>
              </ul>
              <strong>Advanced topics</strong>
              <ul>
                <li><router-link to="/" class="docs-link">Audio-haptic effects</router-link></li>
                <li><router-link to="/" class="docs-link">Vibrotactile effects</router-link></li>
              </ul>
            </div>
          </card>
          <card class="card-dark" ref="refsCard">
            <h3>🔍 Reference</h3>
            <div class="card_links">
              <ul>
                <li><router-link to="/" class="docs-link">HardwareAPI C#</router-link></li>
                <li><router-link to="/" class="docs-link">HardwareAPI C++</router-link></li>
                <li><router-link to="/" class="docs-link">HardwareAPI Python</router-link></li>
              </ul>
              <strong>More</strong>
              <ul>
                <li>
                  <router-link to="/" class="docs-link">HardwareAPI for Unity</router-link>
                </li>
              </ul>
            </div>
          </card>
        </card-row> -->
      </card-layout>

      <div ref="afterText" class="home_wrapper_after-text">

        <!-- <p style="text-align: center; margin-top: 50px;">
          <strong>🚧 More coming soon 🚧</strong>
        </p> -->
        <h2 ref="afterText1">🚧 More coming soon 🚧</h2>
        <p ref="afterText2">
          We're working hard on a new knowledge centre with improved reference material and learning resources based on early feedback from the first two rounds of our evaluation program.
        </p>
        <p ref="afterText3">
          For additional information about the evaluation program, <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfyXbtprAllqkrXz5UWSsYslnL3WLDQli4rem86WMB1AUvtoQ/viewform">get in touch</a>, or head over to <a target="_blank" href="https://talk.haply.co/">talk.haply.co</a> for release announcements and technical discussion.
        </p>
        <!-- <p>
          Not sure where to start? See the full <router-link to="/">documentation</router-link> page for more options.
        </p> -->
      </div>
      <p></p>
    </div><!--home_wrapper-->
    <div class="home_form-text">
      <p>Please use
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScwD34zN xiA33m3dxGxkHwKmHXxaVt_3JayKgyqTGCJ01oKqQ/viewform?usp=pp_url&entry.1710979694=Documentation"
          target="_blank"
        >this form</a> to report a problem with this site or to submit a question, comment, or other feedback.
      </p>
    </div>
  </div>
</template>

<script>
import WebglBackground from "../components/home/WebglBackground";
import DocsHeader from "../components/docs/DocsHeader";
import Loader from "../components/UI/Loader";
import UIAnimations from "@/utils/js/UIAnimations";
// import ElementMagnet from "../classes/ElementMagnet";
import Card from "../components/UI/Card.vue";
import CardRow from "../components/UI/CardRow.vue";
import CardLayout from "../components/UI/CardLayout.vue";
import OpenInNew from "../components/icons/OpenInNew.vue";

export default {
  name: "Home",
  components: {
    WebglBackground,
    DocsHeader,
    Card,
    CardRow,
    CardLayout,
    Loader,
    OpenInNew,
  },
  mounted() {
    UIAnimations.popup(this.$refs.title, 200);
    UIAnimations.popup(this.$refs.underTitle, 300);
    // UIAnimations.popup(this.$refs.introText, 400);
    // UIAnimations.popup(this.$refs.startCard, 500);
    // UIAnimations.popup(this.$refs.docsCard, 600);
    // UIAnimations.popup(this.$refs.downloadsCard, 700);
    UIAnimations.popup(this.$refs.downloadsCard, 400);
    UIAnimations.popup(this.$refs.afterText1, 500);
    UIAnimations.popup(this.$refs.afterText2, 600);
    UIAnimations.popup(this.$refs.afterText3, 700);
    // UIAnimations.popup(this.$refs.learnCard, 800);
    // UIAnimations.popup(this.$refs.guidesCard, 900);
    // UIAnimations.popup(this.$refs.divesCard, 1000);
    // UIAnimations.popup(this.$refs.refsCard, 1100);
    // UIAnimations.popup(this.$refs.afterText, 1200);

    // new ElementMagnet(this.$refs.gettingStarted);
  },
  computed: {
    getDocArticles() {
      if (!this.$store.state.docsTree[0]) return;
      let docTree = this.$store.state.docsTree;
      docTree.sort((a, b) => {
        return parseFloat(b.name) - parseFloat(a.name);
      });
      this.$store.state.currVersion = docTree[0].name;

      return docTree;
    }
  },
  methods: {
    onVersionChange() {
      this.$store.state.currVersion = this.$refs.versionSelect.value;
    },
    getStarted() {
      this.$router.push(
        `/documentation/0.0.1--csharp-reference--inverse3connection--type-definition`
      );
    }
  }
};
</script>

<style lang="stylus">
.no-underline {
  text-decoration none !important
}

.background-fade {
  position absolute
  top 0
  left 0
  width 100%
  height 100%
  background linear-gradient(180deg, #0000, #8af4)
}

.home {
  width 100vw
  max-width 100%
  height 100vh
  display flex
  justify-content center
  align-items center
  color white
  background black

  &_wrapper {
    position relative
    width 520px
    max-width 520px
    
    &_heading {
      .hero_title {
        width 100%
        text-align center
        font-size 5rem
        line-height 150%
        margin-bottom 20px
        color #ffffffff
        font-weight 600

        small {
          font-weight 200
          color white
          display block
          margin-top -0.5em
          font-size 0.75em
        }
      }

      .hero_subtitle {
        width 100%
        text-align center
        color #eee
        font-weight 200
        display inline-block
        font-size 1.5em
        font-style italic
        line-height 100%
        margin-bottom 20px
      }

      h2 {
        text-align center
        font-weight 500
        color #aaa
        margin-bottom 50px
      }

      h3 {
        color #ddd
      }
    }

    p {
      text-align left
      margin-bottom 20px
      text-shadow 0 0 4px black
    }

    &_after-text {
      margin-top 100px

      *:not(strong) {
        font-weight 200
      }

      h2 {
        font-size 1.35em
        text-align center
      }

      p {
        color #ffffffee
        text-align center
      }
    }

    &_dl-links {
      margin-bottom 20px
      padding-left 0px

      li {
        margin 10px 0
        text-shadow 0 0 4px black
      }
    }

    h2, p {
      margin-top 10px
      margin-bottom 10px
    }
  }

  &_form-text {
    & * {
      font-weight 200
    }
    position absolute
    bottom 10px
    left 0
    width 100%
    display flex
    justify-content center
    color #ccc
    font-size 0.9rem
  }
}

.card {
  .card-link-external {
    font-size 1.5rem
    position absolute
    top 0px
    right 0px

    a {
      padding 8px
      width calc(1.5rem + 16px)
      height calc(1.5rem + 16px)
      display block
    }

    *:not([fill="none"]) {
      fill white
    }
  }

  &:hover {
    .card-link-external {
      *:not([fill="none"]) {
        fill black
      }
    }
  }
}

.docs-link {
  &::after {
    content ""
  }
}

.docs-link-primary {
  margin-bottom 0.5em
}

&release-link {
  
}

&release-more {
  text-align right
}
</style>
