<template>
  <footer class="main-footer">
    <div class="main-footer_logo">
      <a href="/"><img src="/assets/images/logoBlack.svg" alt /></a>
      <router-link to="/">MAIN SITE <open-in-new class="icon-inline"/></router-link>
    </div>
    <div class="main-footer_title">
      <h2><router-link to="/"><strong>Inverse3</strong>&nbsp;Developer&nbsp;Hub</router-link></h2>
    </div>
    <div class="main-footer_columns">
      <ul class="main-footer_column">
        <li><router-link to="/">home</router-link></li>
        <!-- <li><router-link to="/getting-started">getting started</router-link></li> -->
        <!-- <li><router-link to="/docs/reference" hidden>documentation</router-link></li> -->
        <!-- <li><router-link to="/downloads">downloads</router-link></li> -->
        <li><router-link to="/releases/latest">downloads</router-link></li>
        <li><a to="https://docs.google.com/forms/d/e/1FAIpQLSfyXbtprAllqkrXz5UWSsYslnL3WLDQli4rem86WMB1AUvtoQ/viewform" target="_blank">get inverse3 <open-in-new class="icon-inline"/></a></li>
        <li><a href="https://docs.google.com/forms/d/e/1FAIpQLScwD34zNxiA33m3dxGxkHwKmHXxaVt_3JayKgyqTGCJ01oKqQ/viewform?usp=pp_url&entry.1710979694=Documentation" target="_blank">feedback <open-in-new class="icon-inline"/></a></li>
      </ul> 
      <ul class="main-footer_column" hidden>
        <li><router-link to="/docs/learn">tutorials</router-link></li>
        <li><router-link to="/docs/how-to">how-to guides</router-link></li>
        <li><router-link to="/docs/concepts">concepts</router-link></li>
        <li><router-link to="/docs/ref">reference</router-link></li>
      </ul>
      <ul class="main-footer_column" hidden>
        <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSfyXbtprAllqkrXz5UWSsYslnL3WLDQli4rem86WMB1AUvtoQ/viewform" target="_blank">get inverse3 <open-in-new class="icon-inline"/></a></li>
        <li><a href="https://docs.google.com/forms/d/e/1FAIpQLScwD34zNxiA33m3dxGxkHwKmHXxaVt_3JayKgyqTGCJ01oKqQ/viewform?usp=pp_url&entry.1710979694=Documentation" target="_blank">feedback <open-in-new class="icon-inline"/></a></li>
        <li><router-link to="/roadmap">roadmap</router-link></li>
        <li><router-link to="/faq">faq</router-link></li>
      </ul>
    </div>
    <span class="main-footer_copyright">Copyright © 2022 Robotiques Haply, Inc. All rights reserved.</span>
  </footer>
</template>

<script>
import OpenInNew from "../icons/OpenInNew";

export default {
  name: "MainFooter",
  components: {
    OpenInNew,
  },
};
</script>

<style lang="stylus" scoped>

img.icon-inline {
  width 0.8em
  height 0.8em
  max-width 0.8em
  max-height 0.8em
}

.main-footer {
  width 100vw
  max-width 100%
  background white
  color #222
  padding-left $horPadding
  padding-right $horPadding
  padding-top 70px
  padding-bottom 100px
  display flex
  justify-content space-between
  flex-wrap wrap
  position relative
  z-index 1

  & a {
    border-bottom 1px solid #0000
    text-decoration none
  }

  & a:hover {
    border-bottom 1px solid #0000
    color $active
    filter brightness(80%)

    svg.icon /deep/ path:not([fill="none"]) {
      fill $active 
    }
  }

  // align-items center
  &_logo {
    display flex
    flex-direction column
    align-items center
    justify-content center
    padding 0 40px
    flex-grow 1
    border-right solid #ddd 1px

    & a:first-child {
      border-bottom none
    }

    & a:first-child:hover {
      border-bottom none
    }

    a {
      display inline-block
      padding-top 20px
      font-weight 200
    }

    img {
      width 120px
      height auto
    }
  }
  
  &_title {
    display flex
    align-items center
    justify-content center
    flex-grow 1
    padding 0 50px

    & a {
      border-bottom none
    }

    & a:hover {
      border-bottom none
    }

    & * {
      font-weight 200
    }

    & strong {
      font-weight 400
    }
  }

  &_columns {
    display flex
    justify-content center
    flex-grow 1

    ul {
      padding 0 50px
      list-style none

      & * {
        font-weight 200
        text-transform uppercase
        text-align center
      }

      &:not(:first-child) {
        //border-left solid #ddd 1px
      }

      li {
        margin 20px 0
        cursor pointer
        color #222
      }
    }
  }

  +below(900px) {
    flex-direction column

    &_columns {
      margin-top 20px

      &_mid {
        border-left solid #ddd 1px
        border-right solid #ddd 1px
        padding 0 20px !important
        margin 0 20px !important
      }

      ul {
        padding 0

        li {
          margin 20px 0
          cursor pointer
        }
      }
    }
  }

  +below(600px) {
    flex-direction column
    padding 10px

    &_columns {
      display block

      &_mid {
        border none
        margin 0 !important
        padding 0 !important
      }

      ul li {
        margin 5px 0
      }
    }
  }

  &_copyright {
    font-weight 200
    display block
    position absolute
    left 0
    right 0
    bottom 0
    text-align center
    margin-bottom 20px
  }
}
</style>