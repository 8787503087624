import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'

Vue.use(Vuex)

const splitDateTime = (str) => {
  const i = str.indexOf('T');
  return {
    date: str.substring(0, i),
    time: str.substring(i + 1),
  }
}

const restructureRelationAttributes = (attributes) => {
  for (const key in attributes) {
    if (attributes.hasOwnProperty(key)) {
      const value = attributes[key]
      if (typeof value == 'object' && value != null && 'data' in value) {
        const data = value.data
        if (data == null) {
          attributes[key] = null
        } else {
          if ('id' in data) {
            attributes[key] = restructureEntry(data)
          } else if ( Array.isArray(data) ) {
            attributes[key] = restructureList(data)
          }
        }
      }
    }
  }
  return attributes
}

const restructureEntry = (entry) => {
  const { date: createdAtDate, time: createdAtTime} = splitDateTime(entry.attributes.createdAt)
  const { date: updatedAtDate, time: updatedAtTime} = splitDateTime(entry.attributes.updatedAt)
  const { date: publishedAtDate, time: publishedAtTime} = splitDateTime(entry.attributes.publishedAt)
  return {
    id: entry.id,
    ... restructureRelationAttributes(entry.attributes),
    createdAtDate: createdAtDate,
    createdAtTime: createdAtTime,
    updatedAtDate: updatedAtDate,
    updatedAtTime: updatedAtTime,
    publishedAtDate: publishedAtDate,
    publishedAtTime: publishedAtTime,
  }
};

const restructureList = (list) => {
  return list.map((entry) => restructureEntry(entry))
};

const restructureListResponse = (response) => {
  return response.data.data.map((entry) => restructureEntry(entry))
};

const restructureEntryResponse = (response) => {
  return restructureEntry(response.data.data)
};

const resolveProductPaths = (products) => {
  const productPaths = {}
  for (const index in products) {
    const product = products[index]
    console.log(product)
    var path = product.slug
    if (product.product_subgroup) {
      path = `${product.product_subgroup.slug}${path}`
    }
    if (product.product_group) {
      path = `${product.product_group.slug}${path}`
    }
    productPaths[path] = product.id
  }
  return productPaths
}

export default new Vuex.Store({
  state: {
    collapsedSideBar: true,
    articleSelectedPart: "",
    currVersion: "0",
    docsTree: [],
    docsArticles: [],
    products: [],
    productGroups: [],
    productSubgroups: [],
    releases: [],
    productPaths: {},
  },
  mutations: {
    onFetchDocsTree(state, data) {
      state.docsTree = data
    },
    onFetchDocsArticles(state, data) {
      state.docsArticles = data
    },
    onFetchProducts(state, data) {
      state.products = data
    },
    onFetchProductGroups(state, data) {
      state.productGroups = data
    },
    onFetchProductSubgroups(state, data) {
      state.productSubgroups = data
    },
    onFetchReleases(state, data) {
      state.releases = data
    },
    onResolveProductPaths(state, data) {
      state.productPaths = data
    }
  },
  actions: {
    async fetchDocs(context) {
      const api = process.env.VUE_APP_API_BASE
      // const docsArticlesResponse = await axios.get(`${api}/docs-articles`)
      const productsResponse = await axios.get(`${api}/products?populate[product_group]=1&populate[product_subgroup]=1&populate[releases][populate][downloads][populate][configuration]=1&populate[releases][populate][downloads][populate][artifacts]=1`)
      const productGroupsResponse = await axios.get(`${api}/product-groups`)
      const productSubgroupsResponse = await axios.get(`${api}/product-subgroups`)
      const releasesResponse = await axios.get(`${api}/releases?populate[downloads][populate][configuration]=1&populate[downloads][populate][artifacts]=1&populate[product][populate][product_group]=1&populate[product][populate][product_subgroup]=1`)

      // const docsArticles = restructureListResponse(docsArticlesResponse)
      const products = restructureListResponse(productsResponse)
      const productGroups = restructureListResponse(productGroupsResponse)
      const productSubgroups = restructureListResponse(productSubgroupsResponse)
      const releases = restructureListResponse(releasesResponse)

      // console.log(docsArticles)

      // context.commit("onFetchDocsTree", treeBuilder(docsArticles))
      // // context.commit("onFetchDocsArticles", docsArticles)
      context.commit("onFetchProducts", products)
      context.commit("onFetchProductGroups", productGroups)
      context.commit("onFetchProductSubgroups", productSubgroups)
      context.commit("onFetchReleases", releases)

      const productPaths = resolveProductPaths(products)

      context.commit("onResolveProductPaths", productPaths);
    }
  },
  modules: {
  }
})
