<template>
  <div class="search-engine">
    <div class="search-engine_search-bar">
      <input
        ref="searchInput"
        v-model="searchText"
        type="text"
        placeholder="Search"
        @click="onFocusIn"
      />
      <img src="/assets/images/searchIcon.svg" @click="openSearch" />
    </div>
    <div v-if="displayRes" class="search-engine_results">
      <ul>
        <div v-if="getDocArticles.length >0">
          <li
            v-for="(doc, i) in getDocArticles"
            :key="i"
            @click="searchClick(doc)"
            class="search-engine_results_found"
          >
            <h4>{{doc.path}}</h4>
            <p>{{doc.article}}</p>
          </li>
        </div>
        <div v-else>
          <li>
            <h4>No Match Found</h4>
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import slugify from "../../utils/js/slugify";

export default {
  name: "SearchEngine",
  data() {
    return {
      searchText: null,
      displayRes: false
    };
  },
  mounted() {
    window.addEventListener("keydown", this.onKeyDown);
  },
  watch: {
    searchText: function(val, oldVal) {
      if (val === "") this.displayRes = false;
      else this.displayRes = true;
    }
  },
  computed: {
    getDocArticles() {
      let docs = JSON.parse(JSON.stringify(this.$store.state.docsArticles));
      let sortedDocs = docs.filter(doc => {
        if (slugify(doc.path) == this.$route.params.articlePath) return;
        return doc.article
          .toLowerCase()
          .includes(this.searchText.toLowerCase());
      });
      sortedDocs.forEach(doc => {
        doc.article =
          "..." +
          doc.article.substring(
            doc.article.indexOf(this.searchText) - 5,
            doc.article.indexOf(this.searchText) + 20
          ) +
          "...";
      });
      return sortedDocs;
    }
  },
  methods: {
    openSearch() {
      this.$refs.searchInput.classList.toggle("on");
    },
    searchClick(data) {
      this.searchText = "";
      this.$router.push("/documentation/" + slugify(data.path));
      this.$refs.searchInput.classList.remove("on");
    },
    onFocusIn() {
      this.$refs.searchInput.select();
      if (this.searchText == null || this.searchText === "")
        this.displayRes = false;
      else this.displayRes = true;
    },
    onFocusOut() {
      this.displayRes = false;
    },
    onKeyDown(e) {
      if (e.keyCode == 27) this.displayRes = false;
    }
  }
};
</script>

<style lang="stylus" scoped>
.search-engine {
  // position relative
  width 50px

  &_search-bar {
    float right
    display flex
    align-items center
    padding 7px
    height 30px
    position relative

    img {
      height 100%
      width auto
      margin-right 5px
      cursor pointer
    }

    input {
      border-radius 5px
      margin-right 10px
      padding 7px
      border solid white 1px
      background black
      color white
      width 0
      opacity 0
      pointer-events none
      transition all 0.3s
      position absolute
      top 0
      right 100%

      &::placeholder {
        color white
        opacity 0
      }

      &.on {
        width 200px
        opacity 1
        width 200px
        pointer-events all

        &::placeholder {
          opacity 1
        }
      }

      &:focus {
        outline none
      }
    }
  }

  &_results {
    width 100%
    max-width 500px
    max-height 400px
    background white
    position absolute
    top $headerHeight
    right 0
    overflow-y scroll
    overflow-x hidden
    border-radius 0 0 20px 20px
    box-shadow rgba(0, 0, 0, 0.3) 5px 0px 10px
    color black

    ul {
      li {
        padding 10px 20px

        &.search-engine_results_found {
          cursor pointer
          position relative
          z-index 2
          beforeHover()
        }

        h4 {
          font-size 0.7rem
          color grey
        }
      }
    }
  }
}
</style>