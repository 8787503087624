<template>
  <div class="doc-article">
    <div class="doc-article_content">
      <div class="doc-article_content_body" ref="articleBody">
        <h1 v-if="title">{{title}}</h1>
        <div v-if="all">
          <div v-if="all.products.length > 0">
            <div v-for="product in all.products">
              <div v-if="product.latest_release && product.latest_release.downloads.length">
                <h3><strong>{{product.name}}</strong></h3>
                <div v-for="download in product.latest_release.downloads">
                  <div v-for="artifact in download.artifacts">
                    <a :href="`${artifact.url}`" class="listed-product">
                      <span>
                        📦 {{product.name}} v{{product.latest_release.version}} {{artifact.name}}
                        <span v-if="download.configuration.length" class="listed-product_configuration">
                          <span v-for="entry in download.configuration">
                            <span v-if="entry.key">{{entry.key}}&nbsp;</span>
                            <span>{{entry.value}}</span>
                          </span>
                        </span>
                      </span>
                      <span>
                        {{product.latest_release.createdAtDate}}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-for="group in all.groups">
            <div v-for="product in group.products">
                <div v-if="product.latest_release && product.latest_release.downloads.length">
                  <h3><strong>{{group.name}}</strong> {{product.name_in_group}}</h3>
                  <div v-for="download in product.latest_release.downloads">
                    <div v-for="artifact in download.artifacts">
                      <a :href="`${artifact.url}`" class="listed-product">
                        <span>
                          📦 {{product.name}} v{{product.latest_release.version}} {{artifact.name}}
                          <span v-if="download.configuration.length" class="listed-product_configuration">
                            <span v-for="entry in download.configuration">
                              <span v-if="entry.key">{{entry.key}}&nbsp;</span>
                              <span>{{entry.value}}</span>
                            </span>
                          </span>
                        </span>
                        <span>
                          {{product.latest_release.createdAtDate}}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            <div v-for="subgroup in group.subgroups">
              <h3><strong>{{group.name}}</strong> {{subgroup.plural_name_in_group}}</h3>
              <div v-for="product in subgroup.products">
                <div v-if="product.latest_release && product.latest_release.downloads.length">
                  <h4>{{product.name_in_subgroup}}</h4>
                  <div v-for="download in product.latest_release.downloads">
                    <div v-for="artifact in download.artifacts">
                      <a :href="`${artifact.url}`" class="listed-product">
                        <span>
                          📦 {{product.name}} v{{product.latest_release.version}} {{artifact.name}}
                          <span v-if="download.configuration.length"  class="listed-product_configuration">
                            <span v-for="entry in download.configuration">
                              <span v-if="entry.key">{{entry.key}}&nbsp;</span>
                              <span>{{entry.value}}</span>
                            </span>
                          </span>
                        </span>
                        <span>
                          {{product.latest_release.createdAtDate}}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>No releases found.</div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "../UI/Card.vue";
import CardRow from "../UI/CardRow.vue";
import CardLayout from "../UI/CardLayout.vue";

export default {
  name: "ReleasesLatest",
  components: {
    Card,
    CardRow,
    CardLayout,
  },
  props: ["all", "title"],
  data() {
    return {
      breakPoint: 1300
    };
  },
  beforeDestroy() {}
};
</script>

<style lang="stylus">
@import url('//cdnjs.cloudflare.com/ajax/libs/highlight.js/11.5.1/styles/stackoverflow-dark.min.css')

.releases-latest {
  &_release {
    &_link {

      & > span {
        display inline-block
      }
    }
  }
}

.listed-product {
  display flex
  flex-direction row
  align-items flex-end
  justify-content space-between
  padding 5px 10px 5px 5px

  & > * {
    display inline-block
  }

  color #08a
        
  &:hover {
    text-decoration underline !important
  }

  &_configuration {
    &::before {
      content " ("
    }
    & > span:not(:first-child) {
      &::before {
        content " / "
      }
    }
    &::after {
      content ")"
    }
  }
}

.fun-button--unused {
  &:hover {
    border-radius 5px
    background #99d
    color #fff
    box-shadow 0px 3px 0px #44a
  }

  &:active {
    border-radius 5px
    background #99d
    color #fff
    transform translate(0px, 1px)
    box-shadow 0px 2px 0px #44a
  }
}

.fade-text {
  opacity 0.5
}

.doc-article {
  width 100vw
  max-width 100%
  min-height 100vh
  display flex
  flex-direction column
  align-items center
  margin-top $headerHeight
  padding-bottom 70px

  +below(1000px) {
    display block
    padding 0 $horPadding
  }

  &_content {
    width 720px

    +below(900px) {
      width 100% !important
    }

    +below(1600px) {
      width 600px
    }

    &_breadcrumb, &_breadcrumb_collapse {
      color grey
      font-size 0.85rem
      padding 20px 0
      font-weight 100
      font-family myriad-pro-semiextended
    }

    &_body {
      color #222

      line-height, h1 {
        margin 30px 0
        font-size 2.4rem
      }

      br {
        margin 0 0
      }

      strong {
        font-weight 600
      }

      h1, h2, h3, h4 {
        border-bottom solid #ddd 1px
        padding-bottom 0.3em
        font-weight 400
      }

      h1, h2, h3, h4, h5, h6 {
        &:target::before {
          content "";
          display block;
          height 70px;
          margin -70px 0 0;
        }
      }

      h2, h3, h4 {
        margin-top 30px
        margin-bottom 20px
      }

      ul {
        padding-left 20px

        li {
          margin 10px 0
          list-style-type disc
        }

        margin-bottom 1rem
      }

      p {
        margin-bottom 1rem
        line-height 1.85em
      }

      a {
        text-decoration none
      }

      table {
        border-spacing 10px
        border-collapse collapse
        border-radius 5px
        background rgb(245, 245, 245)
        empty-cells hide
        width 100%

        td, th {
          padding 10px 20px

          &:empty {
            display: none;
          }
        }

        td {
          font-weight 100
          color #444

          strong {
            font-weight 400
            color black
          }
        }
        
        tbody {
          tr:not(:last-child) {
            border-bottom solid #ddd 1px
          }
        }
      }

      blockquote {
        background $stone !important
        padding 20px
        margin 10px 0
        font-size 0.9em
        border-radius 5px

        :last-child {
          margin-bottom unset
        }
      }

      mark {
        background-color yellow
      }

      img {
        width 100% !important
        height auto
        margin 10px 0
        border-radius 5px
      }

      pre {
        background #1c1b1b !important
        padding 20px
        border-radius 5px
        margin-bottom 10px
        margin-top 10px
        white-space pre-wrap

        code {
          color #eee
          font-family 'Courier', monospace, sans-serif
          font-size 0.8rem

          * {
            font-family 'Courier', monospace, sans-serif
            font-size 0.8rem
          }
        }
      }
    }
  }
}
</style>