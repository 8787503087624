<template>
  <div class="docs">
    <docs-header class="docs-header-light" :breadcrumbs="['Releases', 'Latest']"/>
    <div class="docs_loader" v-if="!docsLoaded">
      <Loader />
    </div>
    <div class="docs_success" v-if="getReleases">
      <div class="docs_success_loaderWrapper" v-if="!docsLoaded">
        <loader />
      </div>
      <release-list :all="getReleases" title="Latest Releases" :breadcrumbs="['Releases', 'Latest']"/>
    </div>
    <div class="docs_fail" v-else>
      <h1>Bad request URL</h1>
      <h2>This documentation end point does not exist</h2>
    </div>
  </div>
</template>

<script>
import DocsHeader from "../components/docs/DocsHeader";
import ReleaseList from "../components/releases/ReleaseList";
import Loader from "../components/UI/Loader";

const byProductGroup = (products, product_groups, product_subgroups, releases) => {
  const ungrouped = []
  const byGroup = {}
  for (var i = 0; i < products.length; i++) {
    const product = products[i]
    if (product.product_group) {
      if (product.product_subgroup) {
        if (product.product_group.id in byGroup) {
          if('subgroups' in byGroup[product.product_group.id]) {
            if (product.product_subgroup.id in byGroup[product.product_group.id].subgroups) {
              byGroup[product.product_group.id]['subgroups'][product.product_subgroup.id]['products'].push(product)
            } else {
              byGroup[product.product_group.id]['subgroups'][product.product_subgroup.id]['products'] = [product]
            }
          } else {
            byGroup[product.product_group.id]['subgroups'] = {}
            byGroup[product.product_group.id]['subgroups'][product.product_subgroup.id] = { products: [product] }
          }
        } else {
          byGroup[product.product_group.id] = {
            subgroups: {}
          }
          byGroup[product.product_group.id]['subgroups'] = {}
          byGroup[product.product_group.id]['subgroups'][product.product_subgroup.id] = { products: [product] }
        }
      } else {
        if (product.product_group.id in byGroup) {
          if ('products' in byGroup[product.product_group.id]) {
            byGroup[product.product_group.id].products.push(product)
          } else {
            byGroup[product.product_group.id] = {
              ...byGroup[product.product_group.id],
              products: [product]
            }
          }
        } else {
          byGroup[product.product_group.id] = { products: [product] }
        }
      }
    } else {
      ungrouped.push(product)
    }
  }
  const flattened_groups = []
  const group_ids = [...Object.keys(byGroup)]
  for (var i = 0; i < group_ids.length; i++) {
    const group_id = group_ids[i]
    const group = byGroup[group_id]
    const flattened_group = {
      ...product_groups.find(({id}) => id == group_id),
      ...group,
    }
    if (flattened_group.subgroups) {
      const subgroup_ids = Object.keys(flattened_group.subgroups)
      const flattened_subgroups = []
      for (var j = 0; j < subgroup_ids.length; j++) {
        const subgroup_id = subgroup_ids[j]
        const subgroup = flattened_group.subgroups[subgroup_id]
        const flattened_subgroup = {
          ...product_subgroups.find(({id}) => id == subgroup_id),
          ...subgroup,
        }
        flattened_subgroups.push(flattened_subgroup)
      }
      flattened_group.subgroups = flattened_subgroups
    }
    flattened_groups.push(flattened_group)
  }
  return {
    products: ungrouped,
    groups: flattened_groups
  }
}

export default {
  components: {
    DocsHeader,
    ReleaseList,
    Loader
  },
  props: {
    vueAppApi: {
      type: String,
      default: process.env.VUE_APP_API,
    },
  },
  data() {
    return {
      docsLoaded: false
    };
  },
  mounted() {},
  computed: {
    getReleases() {
      if (this.$store.state.products[0]) {
        this.docsLoaded = true
        const products = this.$store.state.products
        const productGroups = this.$store.state.productGroups
        const productSubgroups = this.$store.state.productSubgroups
        const latestReleases = []
        const productsWithLatestRelease = []
        for (var i = 0; i < products.length; i++) {
          const product = products[i];
          if (product.releases.length > 0) {
            const releases_by_date_created = [...product.releases].sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
            const latest_release = this.$store.state.releases.find(r => r.id == releases_by_date_created[0].id)
            product['latest_release'] = latest_release
            productsWithLatestRelease.push(product)
            latestReleases.push(latest_release)
          }
        }
        const productsByGroup = byProductGroup(productsWithLatestRelease, productGroups, productSubgroups)
        return productsByGroup
      }
    }
  },
  created() {}
};
</script>

<style lang="stylus" scoped>
.docs {
  &_loader {
    position absolute
    top 0
    left 0
    width 100vw
    max-width 100%
    height 100vh
    display flex
    justify-content center
    align-items center
    background white
  }

  &_success {
    &_loaderWrapper {
      width 100vw
      max-width 100%
      height 100vh
      position absolute
      z-index 1
      top 0
      left 0
      background white
      display flex
      justify-content center
      align-items center
    }
  }

  &_fail {
    width 100vw
    max-width 100%
    height 100vh
    display flex
    flex-direction column
    justify-content center
    align-items center
  }
}
</style>