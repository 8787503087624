import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from '../views/Welcome.vue'
import Docs from '../views/Docs.vue'
import Product from '../views/Product.vue'
import LatestReleases from '../views/LatestReleases.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/documentation/:articlePath+',
    name: 'Docs',
    component: Docs,
  },
  {
    path: '/products/:productPath+',
    name: 'Products',
    component: Product,
  },
  {
    path: '/releases/latest',
    name: 'Latest Releases',
    component: LatestReleases,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
