<template>
  <div class="sidebar">
    <button class="sidebar_button" ref="openButton" @click="openMenu">
      <div class="sidebar_button_bars">
        <div class="sidebar_button_bars_bar"></div>
        <div class="sidebar_button_bars_bar"></div>
        <div class="sidebar_button_bars_bar"></div>
      </div>
    </button>

    <div class="sidebar_menu" ref="sidebar">
      <div class="sidebar_menu_header">
        <h2>Hardware API</h2>
        <img src="/assets/images/close.svg" @click="collapse" class="sidebar_menu_header_closer" />
      </div>
      <div class="sidebar_menu_version-wrapper">
        <h3>Version</h3>
        <select
          class="sidebar_menu_version-wrapper_selector"
          @change="onVersionChange"
          ref="versionSelect"
        >
          <option v-for="(version, i) in getDocArticles" :key="i">{{version.name}}</option>
        </select>
      </div>
      <div class="sidebar_menu_line"></div>
      <div class="sidebar_menu_tree-menu" v-if="getSortedDocs">
        <tree-menu
          v-for="(child, i) in getSortedDocs.children"
          :key="i"
          :name="child.name"
          :children="child.children"
          :depth="0"
          :parentPath="child.name"
        ></tree-menu>
      </div>
    </div>
  </div>
</template>

<script>
import treeBuilder from "../../utils/js/treeBuilder";
import TreeMenu from "./TreeMenu";
export default {
  components: {
    TreeMenu
  },
  data() {
    return {
      version: null
    };
  },
  mounted() {
    this.version = this.$route.params.articlePath.split("-")[0];
    this.$store.state.currVersion = this.version;
  },
  computed: {
    getDocArticles() {
      return this.$store.state.docsTree;
    },
    getCurrVersion() {},
    getSortedDocs() {
      const sortedDocs = this.$store.state.docsTree.find(
        el => el.name == this.$store.state.currVersion
      );
      return sortedDocs;
    },
    getCollapsedSideBar() {
      return this.$store.state.collapsedSideBar;
    }
  },

  methods: {
    onVersionChange() {
      this.$store.state.currVersion = this.$refs.versionSelect.value;
    },
    collapse() {
      this.$refs.sidebar.classList.add("collapse");
    },
    openMenu() {
      this.$refs.sidebar.classList.remove("collapse");
    }
  }
};
</script>

<style lang="stylus" scoped>
.sidebar {
  &_button {
    width 80px
    height $headerHeight
    background black
    position fixed
    top $headerHeight * 2
    left 0
    border none
    padding 15px 10px
    cursor pointer
    transition all 0.2s

    &_bars {
      margin-left auto
      width 30px
      height 100%
      display flex
      flex-direction column
      justify-content space-between

      &_bar {
        transition all 0.1s
        height 2px
        border-radius 100px
        background white

        &:nth-child(1) {
          width 80%
        }

        &:nth-child(2) {
          width 100%
        }

        &:nth-child(3) {
          width 60%
        }
      }
    }

    &:hover {
      .sidebar_button_bars {
        &_bar {
          &:nth-child(1) {
            width 100% !important
          }

          &:nth-child(2) {
            width 20% !important
          }

          &:nth-child(3) {
            width 100% !important
          }
        }
      }
    }
  }

  &_menu {
    min-width 300px
    height 100vh
    position fixed
    top 0
    left 0
    padding 20px
    padding-top $headerHeight * 2 + 20px
    box-shadow rgba(0,0,0,0.1) 0px 0px 16px
    overflow-y auto
    background white
    transition all 0.3s

    &_header {
      display flex
      justify-content space-between
      margin-bottom 20px

      h2 {
        font-weight 400
      }

      &_closer {
        font-size 1.8rem
        height 30px
        width auto
        cursor pointer
      }
    }

    &.collapse {
      transform translate3d(-100%, 0, 0)
    }

    select {
      border-radius none
      color white
      background black
      padding 5px
    }

    &_line {
      margin-top 10px
      margin-left -20px
      margin-right -20px
      border-bottom solid rgba(180, 180, 180, 0.3) 1px
    }

    &_version-wrapper {
      display flex
      align-items center
      margin-bottom 10px

      h3 {
        font-size 1rem
        font-weight 400
        margin-right 20px
      }
    }
  }
}
</style>