<template>
  <div class="article-parts" v-if="getParts().length >0">
    <h2>In this article</h2>
    <div class="article-parts_wrapper">
      <div class="article-parts_wrapper_selector" ref="selector"></div>
      <ul class="article-parts_wrapper_list" ref="list">
        <li
          @mouseenter="onMouseIn(i)"
          @mouseleave="onMouseOut(i)"
          v-for="(part, i) in getParts()"
          :key="i"
          @click="onPartClick(part, i)"
        >{{part}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import anime from "animejs/lib/anime.es.js";

export default {
  name: "ArticleParts",
  props: ["doc"],
  mounted() {
    if (this.$refs.selector)
      this.$refs.selector.style = `height : ${this.$refs.list.children[0].offsetHeight}px`;
  },
  methods: {
    onMouseIn(ind) {
      for (let i = 0; i < this.$refs.list.children.length; i++) {
        const el = this.$refs.list.children[i];
        if (ind == i) {
          el.classList.add("on");
        } else {
          el.classList.remove("on");
        }
      }
    },
    onMouseOut(ind) {
      for (let i = 0; i < this.$refs.list.children.length; i++) {
        const el = this.$refs.list.children[i];
        if (ind == i) {
          el.classList.remove("on");
        }
      }
    },
    getParts() {
      let parts = [];
      this.doc.article.split("\n").forEach(element => {
        if (element.match(/^## /)) {
          let partText = element.replace(/^## /, "");
          partText = partText.replace(/\*\*/, "");
          partText = partText.replace("&lt;", "<");
          partText = partText.replace("&gt;", ">");
          if (partText.length > 30) {
            partText = partText.substring(0, 27) + "...";
          }
          parts.push(partText);
        }
      });
      return parts;
    },
    onPartClick(part, ind) {
      const listHeight = this.$refs.list.offsetHeight;
      this.$store.state.articleSelectedPart = part;

      anime({
        targets: this.$refs.selector,
        translateY: (listHeight / this.getParts().length) * ind,
        duration: 100,
        easing: "easeInOutQuad"
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.article-parts {
  position fixed
  top $headerHeight * 2
  right 0
  padding-right 20px
  min-width 250px

  +below(835px) {
    display none
  }

  h2 {
    padding-top 40px
    padding-bottom 20px
    font-weight 400
  }

  &_wrapper {
    display flex

    &_selector {
      margin-right 10px
      width 1px
      background black
    }

    ul {
      li {
        padding 10px 20px
        cursor pointer
        position relative
        color black
        transition all 0.2s
        text-stroke 0 white
        beforeHover()
      }
    }
  }
}
</style>