<template>
  <a class="card" target="_blank" :href=href>
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: "Card",
  props: ["href"],
};
</script>

<style lang="stylus">
.card {
  display block
  text-decoration none !important
  width calc(50% - 12px)
  border-radius 20px
  position relative
  padding 18px
  transition background-color 0.2s ease-in-out
  -moz-transition: background-color 0.2s ease-in-out;
  transition color 0.1s ease-in-out
  border 1px solid #ddd

  &-dark {
    border 1px solid #def8
    background-color #01020444
    
    &:hover {
      background-color #fff
      border-color clear
      color #222

      li::marker {
        color #777
      }

      a {
        color #08a
        
        &:hover {
          text-decoration underline !important
        }
      }
    }
  }

  &-wide {
    width 100%
    height fit-content
  }

  .card-p {
    margin-top 0.5em
  }
  
  br {
    margin-top 0
  }

  ol, ul {
    padding-left 0.9em
    margin-top 0
    margin-bottom 6px
  }

  strong {
    margin-bottom 6px
  }

  h1, h2, h3, h4, h5, h6 {
    &:first-child {
      margin-top 0
    }
  }

  h3 {
    font-weight 600
    font-size 1.25em
    margin-bottom 12px
  }
  h4 {
    margin-top 1.25em
    margin-bottom 0.25em
  }
  &_links {
    display flex
    flex-direction column
  }
  a {
    text-decoration none !important
  }
}
</style>