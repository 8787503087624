import anime from "animejs"

class UIAnimations {
  popup(target, delay) {
    if ('_isVue' in target && '$el' in target) {
      target = target.$el
    }
    anime({
      targets: target,
      translateY: [20, 0],
      opacity: [0, 1],
      delay: delay || 0
    })
  }
}

const _instance = new UIAnimations
export default _instance