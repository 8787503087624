<template>
  <div class="docs">
    <docs-header />
    <div class="docs_loader" v-if="!docsLoaded">
      <Loader />
    </div>
    <sidebar-menu />
    <div class="docs_success" v-if="getDoc">
      <div class="docs_success_loaderWrapper" v-if="!docsLoaded">
        <loader />
      </div>
      <doc-article :doc="getDoc" />
      <ArticleParts :doc="getDoc" />
    </div>
    <div class="docs_fail" v-else>
      <h1>Bad request URL</h1>
      <h2>This documentation end point does not exist</h2>
    </div>
  </div>
</template>

<script>
import DocsHeader from "../components/docs/DocsHeader";
import SidebarMenu from "../components/docs/SidebarMenu";
import DocArticle from "../components/docs/DocArticle";
import ArticleParts from "../components/docs/ArticleParts";
import Loader from "../components/UI/Loader";

import slugify from "../utils/js/slugify";

export default {
  components: {
    DocsHeader,
    SidebarMenu,
    ArticleParts,
    DocArticle,
    Loader
  },
  data() {
    return {
      docsLoaded: false
    };
  },
  mounted() {},
  computed: {
    getDoc() {
      if (this.$store.state.docsArticles[0]) {
        this.docsLoaded = true;
        const article = this.$store.state.docsArticles.find(
          el => slugify(el.path) == this.$route.params.articlePath
        );

        return article;
      }
    }
  },
  created() {}
};
</script>

<style lang="stylus" scoped>
.docs {
  &_loader {
    position absolute
    top 0
    left 0
    width 100vw
    max-width 100%
    height 100vh
    display flex
    justify-content center
    align-items center
    background white
  }

  &_success {
    &_loaderWrapper {
      width 100vw
      max-width 100%
      height 100vh
      position absolute
      z-index 1
      top 0
      left 0
      background white
      display flex
      justify-content center
      align-items center
    }
  }

  &_fail {
    width 100vw
    max-width 100%
    height 100vh
    display flex
    flex-direction column
    justify-content center
    align-items center
  }
}
</style>