<template>
  <div class="loader">
    <img src="/assets/images/iconBlack.svg" ref="logo" alt />
    <div class="loader_text" ref="loaderText"></div>
  </div>
</template>

<script>
import anime from "animejs/lib/anime.es.js";

export default {
  name: "Loader",
  data() {
    return {
      text: "fetching.docs"
    };
  },
  mounted() {
    // this.text.split("").forEach((char, i) => {
    //   const span = document.createElement("span");
    //   span.classList.add("loader_text_char");
    //   span.innerHTML = char;
    //   this.$refs.loaderText.appendChild(span);
    //   anime({
    //     targets: span,
    //     translateY: [5, 0],
    //     opacity: [0, 1],
    //     loop: true,
    //     direction: "alternate",
    //     duration: 700,
    //     delay: 10 * i
    //   });
    // });
  }
};
</script>

<style lang="stylus">
.loader {
  display flex
  flex-direction column
  align-items center

  img {
    width 40px
    height auto
    animation rotate 1s infinite linear
  }

  &_text {
    &_char {
      text-transform uppercase
      font-size 0.8rem
      display inline-block
      opacity 0
    }
  }
}

@keyframes rotate {
  0% {
    transform rotate(0)
  }

  100% {
    transform rotate(360deg)
  }
}
</style>